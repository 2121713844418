import React, { Component } from 'react';
import { getPK, unpackDataItem } from '@zouggla/zouggla-react-sdk';
import '../App.css';
import autoBind from 'react-autobind';
//import { SignedDataItem } from '@zouggla/zouggla-common';

class Welcome extends Component {
  constructor(props) {
    super(props);
    autoBind(this);
    this.state = {
      jwttoken: this.props.location.state.jwttoken[0],
      email: null
    };
  }
  componentDidMount() {
    getPK('maejohns', 'buVpphR5Sp5zmvJacIWci8UNLW45gofK5MpgCwZW').then(
      privatekey => {
        const pks = privatekey.json.privateKeySet;
        unpackDataItem(pks, this.state.jwttoken).then(cleartext => {
          this.setState({
            dispresponse: JSON.stringify(cleartext, undefined, 4),
            email: cleartext.email
          });
        });
      }
    );
  }

  render() {
    return (
      <div className="App">
        <header className="App-header">
          <p>You shared this with us:</p>
          <pre> {this.state.email} </pre>
        </header>

        <div />
      </div>
    );
  }
}

export default Welcome;
