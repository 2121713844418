import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import * as jose from 'node-jose';
import { KeySet } from '@zouggla/zouggla-common';
import { getPK } from '@zouggla/zouggla-react-sdk';
/*
global.privatekey = {
  keys: [
    {
      kty: 'RSA',
      kid: 'G6Dt38KdUxz70bm22M3125J6xBVXELRMrEVIzwZsT9Y',
      use: 'sig',
      alg: 'PS256',
      key_ops: ['sign', 'verify'],
      e: 'AQAB',
      n:
        'jdnqXEO4AMJ9qVECZ8wRNvqekroaDtzkS_SyF_riokq2NTh2Dwu1lJ9yV3OQC0hubayslXgp8BAhVJGe-6fa5ogl26aHYWQEsELlod3V_9gvDMhGdEGJ9MebVY7nL7UZbJ7loAveVtWdwruARmqcBFIG5eEzSRhznBMac64ScRLXob2lpcJ9EV2LOAjbpwrATQ_bfKwrh9dDjLHEJQlMjQ1IXDpD2TxPV7MOjhbYFY0i2zoeeSgArLZaCWKZY5mMyIDC1O7RrLPc34mCW_C94ALGAo68WW2g8pxsX2t1xASpDfvjHLH5Wq4a-pEk47dabfk-E54oB85ZBLUvj6i5JQ',
      d:
        'Op8wJ2klBctauzprUBIZmyr_lYurcTEAeBNr9lUMjB1sJ5ppz9X6-KzcSB_OC5unZ1PRDwRV8Iv5-kUj-sRnxpXFb3zmvezjsyQyWi6fXdgtuY6a7ziWlr5XB4jDKgghta4FH5tIC4LEa9GpNXChgXeUujPTSgX84SdT6zKpFGkjX7-l0N38T8NGr-AU_aA7oC67X9hp1ZTVo7OoLC6D8BMfAEc0x5NzByISI0xWywKrJS7VJPjpQbkjA5TeSRi7gA3elN7BchRql6VjeLNFVaYx9Dq5rBNkBDIjT_jcFRIYrfUCJD09FDN6EZ6pkJ9w1QYnhzOcQuVsOwEzRiJfEQ',
      p:
        'x4Srxs63ndDsYEEe_dw-0AVnWMuD21eM1x9H324OkruvMDM65scp68L9g7agi2elRCCgQhQasQ_erVZe4LohXIQN7yZydPnk0wPmdR-XDrlAE_AQF1EIC2tIGXGVWlWj-4cjhT7Gwq1LOxfzSu1jNDm-LGQQem7wRO7l30gt7ec',
      q:
        'tgILzm0krxrwrAzQ8Uj9OpG9f5E_0GpgeSA8EilwAY2zGJ6ZKGCY9kCwUBMZpkDaUHUqka_3904tuCX9ndZbctchvbBmwG3KfsK4r7dQl0kh-exDm6E-58P9yQ9e5aZGiSeXEiADeVuGqFWfXxeNfZzHv6EBYGgd3otq_JPzRxM',
      dp:
        'ft1VfKylBKx8IRhHZJRPPGazODrwNOCOpZs2UFPi4FqHItbwdR3fAhKSsNXHb90PsSotvNabwkqdBQsOeUbhP7qYQaDo1oivf53TRFY57xrMK-FR2gGkY95rkgIvPamHA8ECTQ-FwvP-Ci1IfjRECElPK1rVP4SbMrnVYQy8Z7E',
      dq:
        'NVXyKcLYHiwx3OlAjYSLGI--zVQ-NBAIR1aH4_aaqQniMtO_JBLyoC8iu8RtHsggcbV0DQ9ZBfK1-SHA6XD4iXW7oH_CDJ_f2DetsWhxyHOY7kkrIr8k1PwdMb88WuXYA7dDUP9qpTSKCGhW9KHNR8Ma0fAXnC3GmwGWD4A3qVs',
      qi:
        'lJUsPwEl5M92FGd4n1N6QVo91yLef6S1fODMaQwfiIqhpkq6CJK3ZGpQ4Cn9q6f1O-HB6lGISsztg3YFJ7rHj5jimYtwfvjy1dahJyOdOU7u46NOnDz85-QNuDm6sPZUUiWuGskMNkJtTTNzZaUXK8jLuIEuI1TwtNNe0Gugth0'
    },
    {
      kty: 'RSA',
      kid: 'ugqkVIfsJsW1UNDGtVsECvfIVvPgNiJs-xJliozj8Vk',
      use: 'enc',
      alg: 'RSA-OAEP', //-256',
      key_ops: ['encrypt', 'decrypt', 'wrapKey', 'unwrapKey'],
      e: 'AQAB',
      n:
        '1Tv90vXvyfBQZdBHnXdKk9mDoTXbSpze4hRIqz0Yk1YMgC-ry3BUxMIBzS8-7ikVOAn6jUjbawjtX6mliVJ4i8uDylxKYMEtyszQFto6iR6I_ANPwZEYfUkfBnecxJk8paBo_taguUQJk-0hFIVJrEaKjQ5kN7Jcpp4GLhzfNMpXn8dQuitbR6AiISC2FLwq2CYdvjDaemAKMeeUL_OEmnygYAVeDeKBqR8TFXSe6AYbWGYwvprHFPeRtTZvWaSsCB499wgR7ryEg_Bsb181IoIWiah2LYuUXCpgrEBQn_XgiXC37-Mm_9n8yJMzKgWQtXx7EaJ5gfYXhNipw0j8xQ',

      
      d:
        'mvikuoN_KFZ9N-GtiqW-D8IPxkQsAggMfGadgcY_bCUnpKoAwzP6h50wTf0-UTyObVHjRHdk8F9mlRXodur3LTJVr-ZvL-5152P-__zOegU1LWMCvf1DvRo9cCfSSZfM2drBNCSsXrkx9M5sMkvEhW4nF-kTsN7yMy259B2LowLv2jpH98xemRKVyDfG792qR-FJOHCLRN3_02exi3NFV6WYs-Qz5HPWltJR3a3GlHqOj9J_W612p30A__Rqfpnj6yQ8EIRMC4ICHWY9n-eqsga-AWKjJwWTVxqSQ6kL8LDetVhj4oyOZzS1fihJGdctt2FTUJnqEu_uM0dzTejhKQ',
      p:
        '8uj5H1N8oxSsEyIsSqvX3A6owWzxKZAV9Yv7daNucQgvYe98ACaI0xUbqQpZJQzwpRALqWNpwwP2HYc-EVV6qqpNpTeSOI4T24FA41Oa_2qg9eHAiBiffVRWFcYpWXE98MalixPkdvZsd7sWh-eZuigL7_muhiEcLuY7Q0bcuSs',
      q:
        '4LmhwN0mtuE9ZeQKUiZ3nlYFRY_l92GqtFXnyvyXC59mfh9fbx71heM-Q6KlNVOBMu9PQcNJFRErM85mNtmrTEC8jZX52a362mWiF9nEpbghMc-6wfItA-HMRB1V0fgTBUJBcheOPhJVTBuXcHV4II9bsj5luxbXNxWq8Q0GSc8',
      dp:
        'mxmy6BOww5iRfu4VBeDPxcz-Z_Md0YIU42JsP2MJQ9cMGiaGMLpqJw2VScw3u3aVuW-TYqb9gu3mmlgeg4bm-k4fodzn2oCtoTzYp85ca7Yz7IwnGT9PpwDQrrqG8HtkJ3dPgIxFFsil3ZVx_3kcKUckyCfPDgnaKwAK2xxGF10',
      dq:
        'Ed5QwUAUYvg35JEkTPHoEvvuAWD2vRvT8fDetGIvNGphQOgHs5M3nIS8H66gCkRwgndKywTXG8i4sa7Yq8XU_AQtuehR7IAL8S-hmtni6_ZfFVHaHK-_6o4aqtPWThK2mHVzNLowwji89YdAHF4KxjP_64VQm3aueB_N7srFVDs',
      qi:
        'y4hoF-O_v-4XDZFhVYtckS0wDPOkpMf98LtvY2i0E5VMZQwGlmZNGDKd5mmQYul6PfEe0T4LbKmg6EbjrIQxtcRSoBXlsmdCcF2IX6hWjJprAMcn3z424Ry4_dalLEIorARqsIyax5C9j1cDUkUXn9tu7c9KmvtlTplf5s3HPJQ'
    }
  ]
};
*/
const partnerID = 'maejohns';
const apiKey = 'buVpphR5Sp5zmvJacIWci8UNLW45gofK5MpgCwZW';
getPK(partnerID, apiKey).then(privatekey => {
  global.privatekey = privatekey.json.privateKeySet;

  jose.JWK.asKeyStore(global.privatekey).then(keyset => {
    global.keyset = new KeySet(keyset);
  });
});

ReactDOM.render(<App />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
